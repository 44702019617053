<template>
  <div>
    <!-- <BaseHeader :title="'Checkout'"></BaseHeader> -->
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>

    <div v-else class="card mt-2 border-0 bg-white shadow">
      <div class="card-body">
        <div class="row" v-if="Object.keys(order).length > 0">
          <div class="col-12 text-left" v-if="order.is_paid">
            <div class="media alert alert-success">
              <div class="mr-3">
                <a
                  href="#"
                  class="
                    btn
                    bg-transparent
                    border-success
                    text-success
                    rounded-round
                    border-2
                    btn-icon
                  "
                >
                  <i class="icon-checkmark3"></i>
                </a>
              </div>

              <div class="media-body">
                Your order is paid!
                <div class="text-muted">We've received your order payment!</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-8 text-left" sm="6">
            <h1
            class="order-id"
            >
              Order ID #{{ order.id || "" }}
            </h1>
            <p>
              <strong>{{ order.subject_area_text || "" }}</strong> |
              <strong>{{ order.academic_level_text || "" }}</strong> |
              <strong>{{ order.pages || "" }} page(s)</strong>
            </p>
          </div>

          <div class="col-12 col-sm-6 col-md-4" sm="6">
            <div class="m-0 text-sm-right text-left pb-2">
              <span class="font-weight-bold"> </span>
              <div class="btn-group my-1">
                <button
                  class="btn btn-outline-success"
                  data-toggle="modal"
                  data-target="#tipModal"
                >
                  Tip Writer
                </button>
                <router-link
                  :to="{ name: 'Order', params: { id: order.id } }"
                  class="btn btn-success"
                  >View Order</router-link
                >
              </div>
            </div>
          </div>

          <div class="col-12 border-top py-2 ">
            <div class="row">
              <div class="col-12 col-sm-8 col-md-9 table-responsive">
                <table
                  class="table table-borderless table--custom text-left "
                  style="font-size: 13px !important"
                >
                  <tr>
                    <td class="color-faded " >Topic</td>
                    <td class="order-option-value " > {{ order.title || "" }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Type of Paper</td>
                    <td>{{ order.document_type_text || "" }}</td>
                    <!-- <td></td> -->
                  </tr>

                  
                  <tr>
                    <td>Paper Format / Style</td>
                    <td>{{ order.style_text || "" }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Date Ordered</td>
                    <td>{{ order.date_placed || "" }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Order Deadline</td>
                    <td
                      :class="
                        order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                      "
                      class="font-weight-bold"
                    >
                      {{ order.deadline && order.deadline.date || "" }}
                    </td>
                    <td
                      :class="
                        order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                      "
                      class="font-weight-bold"
                    >
                      {{ order.deadline && order.deadline.past_due ? "Elapsed" : "" }}
                      <span class="font-weight-black">{{ order.deadline && order.deadline.ellapsed || "" }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Urgency</td>
                    <td>
                      <span
                        v-if="order.deadline"
                        :class="order.deadline.past_due ? 'text-danger' : 'text-success'"
                        >{{ order.urgency_text_hrs || "" }}</span
                      >
                    </td>
                    <!-- <td></td> -->
                  </tr>

                  <tr>
                    <td>Number of sources</td>
                    <td>{{ order.no_sources || "0" }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Writer Level</td>
                    <td>{{ order.writer_level_desc || "0" }}</td>
                    <!-- <td></td> -->
                  </tr>

                  <tr>
                    <td>Additional Services</td>
                    <td>
                      <table class="table table-borderless">
                        <tr
                          v-for="(item, index) in order.additional_services_text"
                          :key="index + 'add'"
                        >
                          <td>{{ item[0] }}</td>
                          <hr />
                        </tr>
                      </table>
                    </td>
                    <!-- <td></td> -->
                  </tr>

                  <tr>
                    <td>Spacing</td>
                    <td>{{ order.spacing_text || "" }}</td>
                    <!-- <td></td> -->
                  </tr>

                  <tr>
                    <td>Number of Page(s)</td>
                    <td>
                      {{ order.pages || "" }} 
                      <!-- / Aprox {{ no_of_words || "275" }} words -->
                    </td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Number of Slide(s)</td>
                    <td>{{ order.slides || "" }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>Number of Chart(s)</td>
                    <td>{{ order.charts || "" }}</td>
                    <!-- <td></td> -->
                  </tr>

                  <tr
                    v-for="(extra, index) in order.extras"
                    :key="index + 'oextras'"
                    :class="extra.paid !== '0' ? 'alert-success' : 'alert-danger'"
                  >
                    <td>{{ extra.name || "" }}</td>
                    <td>${{ extra.cost || 0 }}</td>
                    <td>
                      <button
                        v-if="extra.paid !== '0'"
                        class="ml-1 btn btn-sm bg-teal"
                      >
                        Paid
                      </button>
                      <span
                        v-else
                        class="d-flex justify-content-center align-items-center"
                      >
                        Unpaid
                        <button
                          @click="deleteExtra(extra.id)"
                          class="mx-1 btn btn-sm btn-outline-danger"
                        >
                          Delete
                        </button>
                      </span>
                    </td>
                  </tr>
                  <tr class="alert alert-primary border-0">
                    <td class="font-weight-bold">Extras Total</td>
                    <td class="font-weight-bold" style="font-size: 18px;">${{ order.extras_total || 0 }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Total Order Cost</td>
                    <td class="font-weight-bold" style="font-size: 18px;">${{ order.cost }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr class="alert alert-success border-0">
                    <td class="font-weight-bold">Paid</td>
                    <td class="font-weight-bold" style="font-size: 18px;">${{ order.total_paid || 0 }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr class="alert alert-danger border-0">
                    <td class="font-weight-bold">Balance</td>
                    <td class="font-weight-bold" style="font-size: 18px;">${{ order.balance || 0 }}</td>
                    <!-- <td></td> -->
                  </tr>
                </table>

                <div class="order-details-item my-4 order-details-item-paper-detailes">
                  <div class="order-details-header d-flex justify-content-between">
                      <div class="title py-2 ">Paper details</div>
                  </div>                                     
                  <div class="order-details-block">
                      <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                        <pre style="padding: 4px !important">                 
                        <span v-html="order.description"></span>
                        </pre>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-4 col-md-3 text-left">
                <div
                style="border: 1px solid #E7E9EC !important; border-radius: 15px!important; "
                  class="card checkout--custom  py-3"
                  
                >
                  <div class="card-body">
                   
                    <h5 class="summary__title border-bottom">Order Checkout</h5>
                    <span class="color-faded" v-html="payment_vars.message"></span>
                    
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small class="top_for_client" >Order Price</small>
                      <h3 class="font-weight-black ml-1 my-0">
                        {{ order.cost || "" }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small class="top_for_client">Total Extras</small>
                      <h3 class="font-weight-black ml-1 my-0">
                        {{ order.extras_total || 0 }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small class="top_for_client">Paid</small>
                      <h3 class="font-weight-black text-success ml-1 my-0">
                        {{ order.total_paid || 0 }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small class="top_for_client">Balance</small>
                      <h3 class="font-weight-black text-danger ml-1 my-0">
                        {{ order.balance || 0 }} USD
                      </h3>
                    </div>
                    <!-- <a
                      class="btn btn-primary btn-block mt-2 btn-lg py-2"
                      :href="payment_vars.checkout || '#'"
                      >Card Checkout</a
                    >
                    <button
                      class="btn btn-outline-primary btn-block btn-lg mt-2 mb-1"
                      @click="walletPaymentUrl"
                    >
                      Pay From Wallet
                    </button>
                   
                    <button
                      class="btn btn-outline-danger btn-block btn-lg"
                      @click="loadPaymentUrl"
                    >
                      Refresh Payment Token
                    </button>  -->
                    <div class="paymentsBy mx-3">
                      <div class="stripeHead">
                        <p>Secure Payment </p>
                      </div>
                      <img src="/orders/allPayments.png" alt="Payment Methods">
                    </div>
                  </div>
                </div>
                <!-- {{payment_vars.checkout}} -->
                <div v-if="!order.is_paid" class=" pay-wrapper-new  ">
                                                                                                            
                  <button @click="handleCard"  type="button" class="btn-check  btn-outline-success nav-paycard">
                      <div class="wrap-payment d-flex">
                          <div class="wrap-payment-img">
                              <img src="/orders/cards-pay.svg">
                          </div>
                          <div class="button-title">
                              I want to pay by Card<br>
                              <small>Insert your card details</small>
                          </div>
                      </div>    
                    </button>
                   <button @click="walletPaymentUrl" type="button" class="btn-check btn-outline-primary nav-paypal">
                      <div class="wrap-payment d-flex">
                          <div class="wrap-payment-img">
                              <img src="/orders/pay_another_way.svg">
                          </div>
                          <div class="button-title">
                            Pay From Wallet <br>
                              <small>You’ll be redirected to choose another payment method</small>
                          </div>
                      </div>
                  </button>
                   
                </div>
                <div class="col-12 card  text-left" v-else>
                  <div class="media my-2 alert alert-success">
                    <div class="">
                      <a
                        href="#"
                        class="
                          btn
                          bg-transparent
                          border-success
                          text-success
                          rounded-round
                          border-2
                          btn-icon
                        "
                      >
                        <i class="icon-checkmark3"></i>
                      </a>
                    </div>

                    <div class="media-body">
                      Your order is paid!
                      <div class="text-muted">We've received your order payment!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Order",
  components: {},
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("payment", ["paymentForm", "payment_vars"]),
  },
  data() {
    return {
      is_loading: false,
    };
  },

  methods: {
    ...mapActions("order", ["_getOrder", "deleteExtras"]),
    ...mapMutations("order", ["unsetOrder"]),
    ...mapActions("payment", ["_getPaymentUrl"]),
    ...mapMutations("payment", ["_setPaymentForm", "setPaymentUrl"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),

    walletPaymentUrl() {
      let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/WalletCheckout`;
      this.$store.commit("payment/setPaymentUrl", paymentUrl);
      this.paymentForm.type = "order";
      this.paymentForm.reference = this.order.id;
      this.is_loading = true;
      this._getPaymentUrl(this.paymentForm)
        .then((res) => {
          console.log(res);
          if (res.data.Type) {
            this.$notify({
              title: "Success",
              text: res.data.Message || "Payment successiful!",
              style: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              text: res.data.Message || "Payment could not be processed!",
              style: "danger",
            });
          }

          this.is_loading = false;
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
          this._getOrder(this.$route.params.id);
          this.getSidebarMenu();
        });
    },

    handleCard(){
      console.log("this is the link =====>", this.payment_vars.checkout )
      // this.$router.push(
      //   this.payment_vars.checkout
      // );
      document.location.href = this.payment_vars.checkout
    },
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    async loadPaymentUrl() {
      let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl`;
      this.$store.commit("payment/setPaymentUrl", paymentUrl);
      this.$store.state.loading = true;
      await this._getOrder(this.$route.params.id);
      this.paymentForm.type = "order";
      (this.paymentForm.amount = this.order.cost),
        (this.paymentForm.reference = this.order.id);

      this.paymentForm.call_back = document.location.href;
      await this._getPaymentUrl(this.paymentForm);
      await this.$nextTick();
    },
  },

  async mounted() {
    await this.loadPaymentUrl();
  },

  beforeDestroy() {
    this.unsetOrder();
  },
};
</script>
